import React from 'react';
import './scrollup.css'
import useScrollTo from '../../hooks/useScrollTo';

const ScrollUp = () => {

  window.addEventListener("scroll", function() {
    const scrollUp = document.querySelector(".scrollup");
    if (this.scrollY > 200) scrollUp.classList.add("show-scroll");
    else scrollUp.classList.remove("show-scroll")
  })

  const scrollTop = useScrollTo()

  return (
    <button  className="scrollup" onClick={() => {
      scrollTop({
        to: '#home',
        duration: 900,
      })
    }}>
      <i className="uil uil-arrow-up scrollup__icon"></i>
    </button>
  );
};

export default ScrollUp;