import React, { useState } from 'react'; 
import './work.css'
import '../work/Works'
import Works from '../work/Works';
import '../../utils/i18n'
import { useTranslation } from 'react-i18next'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Work = () => {
  const {t} = useTranslation();

  useState(() => {
    AOS.init({
      duration: 800,
      once: true,
    })
  },[])

  return (
    <section className="work section" id='portfolio'>
      <h2 data-aos="fade-right" className="section__title">{t('Portfolio')}</h2>
      <h2 data-aos="fade-down" className='section__subtitle'>{t('Aqui estão alguns de meus projetos que desenvolvi. Você pode encontrar mais projetos no meu')} <span className="work_span"><a href="https://github.com/mattchagaz" target='_blank'>GitHub</a> <i className='bx bxl-github'></i></span></h2>
      <Works />
    </section>
  )
}

export default Work;