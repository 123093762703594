import React from 'react';

const WorksItems = ( {item} ) => {
  return (
    <div data-aos="slide-up" className="work__card" key={item.id}>
      <img src={item.image} alt="#" className='work__img' />
      <h3 className="work__title">{item.title}</h3>
      <h4 className="work__techs">{item.tech}</h4>
      <div className="work__buttons">
        <a href={item.demo} target='_blank' rel="noreferrer" className="work__button"  >
          Demo <i className="bx bx-right-arrow-alt work__button-icon"></i>
        </a>
        <a href={item.repository} target='_blank' rel="noreferrer" className="work__button"  >
          Repository <i className="bx bx-right-arrow-alt work__button-icon"></i>
        </a>
      </div>
    </div>
  )
}

export default WorksItems;