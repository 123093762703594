import React, { useState } from 'react'
import "./header.css";
import useScrollTo from "../../hooks/useScrollTo";
import '../../utils/i18n'
import { useTranslation } from 'react-i18next'

const Header = () => { 
    /* Toggle Menu */
  const[Toggle, showMenu] = useState(false);
  const {t} = useTranslation();
  const scrollTo = useScrollTo();
  
  return (
    <header className="header">
      <nav className="nav container">
        <a href="./" className="nav__logo">&lt;/Matheus Chagas&gt;</a>

        <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
          <ul className="nav__list grid">
            <li className="nav__item">
              <a onClick={() => {scrollTo({
          to: '#home',
          duration: 900,
        })}} className="nav__link active-link">
                <i className="uil uil-estate nav__icon"></i> {t('Home')}
              </a>
            </li>

            <li className="nav__item">
              <a onClick={() => {scrollTo({
          to: '#about',
          duration: 900,
        })}} className="nav__link">
                <i className="uil uil-user nav__icon"></i> {t('About')}
              </a>
            </li>

            
            <li className="nav__item">
              <a onClick={() => {scrollTo({
          to: '#services',
          duration: 900,
        })}} className="nav__link">
                <i className="uil uil-briefcase nav__icon"></i> {t('Services')}
              </a>
            </li>


            <li className="nav__item">
              <a onClick={() => {scrollTo({
          to: '#skills',
          duration: 900,
        })}} className="nav__link">
                <i className="uil uil-file-alt nav__icon"></i> {t('Skills')}
              </a>
            </li>

            <li className="nav__item">
              <a onClick={() => {scrollTo({
          to: '#portfolio',
          duration: 900,
        })}} className="nav__link">
                <i className="uil uil-scenery nav__icon"></i> {t('Portfolio')}
              </a>
            </li>

            <li className="nav__item">
              <a onClick={() => {scrollTo({
          to: '#contact',
          duration: 900,
        })}} className="nav__link">
                <i className="uil uil-message nav__icon"></i> {t('Contato')}
              </a>
            </li>
          </ul>

          <i className="uil uil-times nav__close" onClick={() => showMenu(!Toggle)}></i>
          
        </div>

        <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
          <i className="uil uil-apps"></i>
          </div>
      </nav>
    </header>
  )
}

export default Header 