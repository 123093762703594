import React, { useState, useEffect } from "react";
import "./services.css"
import '../../utils/i18n'
import { useTranslation } from 'react-i18next'


const Services = () => {
  const {t} = useTranslation();

  const [toggleState, setToggleState] = useState(0)

  const toggleTab = (index) => {
    setToggleState(index);
  }

  return (
    <section className="services section" id="services">
      <h2  className="section__title">{t('Services')}</h2>
      <h2 data-aos="fade-down" className='section__subtitle'>{t('Veja meus serviços e o que posso oferecer pra você')}</h2>

      <div className="services__container container grid">
        <div className="services__content">
          <div>
          <i className="uil uil-cog services__icon"></i>
              <h3 className="services__title">{t('APIs de')} <br /> {t('Integration')}</h3>
          </div>
          
          <span className="services__button" onClick={() => toggleTab(1)}>{t('View More')} <i className='bx bxs-chevrons-right' ></i></span>
          

          <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
            <div className="services__modal-content">
              <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

              <h3 className="services__modal-title">{t('APIs Integration')}</h3>
              <p className="services__modal-description">{t('Ampliando as funcionalidades do seu site com integrações poderosas')}</p>

              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">{t('Integração de API de Tradução')} </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">{t('Integração de APIs diversificadas para o Projeto')} </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">{t('Facilidade na configuração e conexão com APIs populares e amplamente utilizadas')}</p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">{t('Utilização de widgets e componentes para adicionar funcionalidades específicas de APIs ao seu site')}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="services__content">
          <div>
              <i className="uil uil-arrow services__icon"></i>
              <h3 className="services__title">{t('Web')} <br /> {t('Development')}</h3>
          </div>
          
          <span className="services__button" onClick={() => toggleTab(2)}>{t('View More')} <i className='bx bxs-chevrons-right' ></i></span>
          

          <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
            <div className="services__modal-content">
              <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

              <h3 className="services__modal-title">{t('Web Development')}</h3>
              <p className="services__modal-description">{t('Serviços com mais de 2 anos de experiência. Oferecendo um trabalho de qualidade para todos os clientes')}</p>

              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">{t('Desenvolvimento de páginas web')}</p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">{t('Desenvolvimento de aplicaçõess web')}</p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">{t('Otimização de sites para mecanismos de busca (SEO)')}</p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">{t('Manutenção e atualização de websites')}</p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">{t('Consultoria em desenvolvimento web')}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="services__content">
          <div>
          <i className="uil uil-focus services__icon"></i>
              <h3 className="services__title"> {t('Mobile')} <br /> {t('Responsivity')}</h3>
          </div>
          
          <span className="services__button" onClick={() => toggleTab(3)}>{t('View More')} <i className='bx bxs-chevrons-right' ></i></span>
          

          <div className={toggleState === 3 ? "services__modal active-modal" : "services__modal"}>
            <div className="services__modal-content">
              <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

              <h3 className="services__modal-title">{t('Mobile Responsivity')}</h3>
              <p className="services__modal-description">{t('Garantindo a melhor experiência em todos os dispositivos')}</p>

              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">{t('Layout Adaptável')}</p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">{t('Funcionalidade consistente em desktop, tablet e smartphones')}</p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">{t('Carregamento rápido e eficiente em desktop e dispositivos móveis')}</p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">{t('Redimensionamento automático para melhor exibição em diferentes telas')}</p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">{t('Funcionamento correto em diferentes navegadores tanto em desktop quanto em dispositivos móveis')}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services