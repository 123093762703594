import React, { useEffect } from 'react'
import "./home.css"
import Social from './Social'
import Data from './Data'
import ScrollDown from "./ScrollDown";
import AOS from 'aos'

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    })
  },[])

  return (
    <section data-aos="fade-down" className="home section" id='home'>
      <div className="home__container container grid">
        <div className="home__content grid">
            <Social />

            <div className='home__img'></div>

            <Data />
        </div>

        <ScrollDown />
      </div>
    </section>
  )
}
export default Home