import React, {useRef} from "react";
import useScrollTo from "../../hooks/useScrollTo";
import { TouchRipple } from "../TouchRipple/TouchRipple";
import PropTypes from 'prop-types';
import "./styles.css";

export function Button(props) {
  const rippleRef = useRef();
  const { text, to, duration, offset, onClick, icon} = props;
  const scrollTo = useScrollTo();
  

  return (
    <button onMouseDown={(e) => {
      rippleRef.current.start(e);
    }} onMouseUp={(e) => {
      rippleRef.current.stop(e);
    }} onMouseLeave={(e) => {
      rippleRef.current.stop(e);
    }} onClick={() => {
      if (to) {
        scrollTo({
          to: to,
          duration: duration,
          offset: offset,
        })
      }
      if (onClick) {
        onClick();
      }
    }} className="button button--flex">
      {text}
      {icon}
       <TouchRipple ref={rippleRef} />
    </button>
  )
}

Button.propTypes={
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  duration: PropTypes.number,
  offset: PropTypes.number,
  onClick: PropTypes.func,
  icon: PropTypes.element
}

export default Button