import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Social = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    })
  }, [])


  return (
    <div data-aos="fade-left" className="home__social">
      <a href="https://wa.me/5551992994382" className="home__social-icon" target="_blank" rel="noreferrer">
        <i className="uil uil-whatsapp"></i>
      </a>

      <a href="https://www.linkedin.com/in/matheusferrazchagas/" className="home__social-icon" target="_blank" rel="noreferrer">
        <i className="uil uil-linkedin-alt"></i>
      </a>

      <a href="https://github.com/mattchagaz" className="home__social-icon" target="_blank" rel="noreferrer">
       <i className="uil uil-github"></i>
      </a>
    </div>
  )
}

export default Social