import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Button from '../button/Button';
import '../../utils/i18n'
import { useTranslation } from 'react-i18next'
import './contact.css';
import AOS from 'aos'
import 'aos/dist/aos.css'

const Contact = () => {
  const {t} = useTranslation();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_mj1i88n', 'template_rxshtp8', form.current, '638TAMHNQEthqR00Q')
    e.target.reset()
  };

  useState(() => {
    AOS.init({
      duration: 800,
      once: true,
    })
  },[])

  return (
    <section className="contact section" id="contact">
      <h2 data-aos="fade-right" className="section__title">{t('Get in touch')}</h2>

      <div className="contact__container container grid">
        <div data-aos="fade-down" className="contact__content">
          <h3 className="contact__title">{t('Talk to me')}</h3>

          <div className="contact__info">
            <div className="contact__card">
              <i className="bx bx-mail-send contact__card-icon"></i>

              <h3 className="contact__card-title">Email</h3>
              <span className="contact__card-data">contato@mattchagaz.com</span>

              <a href="mailto:contato@mattchagaz.com" className="contact__button">{t('Write me')} <i className="bx bx-right-arrow-alt contact__button-icon"></i></a>
            </div>

            <div className="contact__card">
              <i className="bx bxl-whatsapp contact__card-icon"></i>

              <h3 className="contact__card-title">Whatsapp</h3>
              <span className="contact__card-data">+55 51 99299-4382</span>

              <a href="https://wa.me/5551992994382" target='_blank' className="contact__button">{t('Write me')} <i className="bx bx-right-arrow-alt contact__button-icon"></i></a>
            </div>

            <div className="contact__card">
              <i className="bx bxl-linkedin contact__card-icon"></i>

              <h3 className="contact__card-title">LinkedIn</h3>
              <span className="contact__card-data">linkedin.com/in/matheusferrazchagas</span>

              <a href="https://www.linkedin.com/in/matheusferrazchagas/" target='_blank' className="contact__button">{t('Write me')} <i className="bx bx-right-arrow-alt contact__button-icon"></i></a>
            </div>
          </div>
        </div>

        <div data-aos="fade-up" className="contact__content">
          <h3 className="contact__title">{t('Write me your project')}</h3>

          <form ref={form} onSubmit={sendEmail} className="contact__form">
            <div className="contact__form-div">
              <label className="contact__form-tag">{t('Name')}</label>
              <input type="text" name='name' className='contact__form-input' placeholder={t('Insert your name here')} />
            </div>

            <div className="contact__form-div ">
              <label className="contact__form-tag">{t('Email')}</label>
              <input type="email" name='email' className='contact__form-input' placeholder={t('Insert your email here')} />
            </div>

            <div className="contact__form-div contact__form-area">
              <label className="contact__form-tag">{t('Project')}</label>
              <textarea name="project" cols="30" rows="10" className='contact__form-input' placeholder={t('Write your project here')}></textarea>
            </div>

            <Button icon={<i className="uil uil-arrow-right"></i>} text={t('Send')}/>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;