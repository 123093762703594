import React, { Fragment } from 'react'
import './App.css'
import Header from './components/header/header'
import Home from './components/home/Home'
import About from './components/about/About'
import Qualification from './components/qualification/Qualification'
import Services from './components/services/Services'
import Skills from './components/skills/Skills'
import Work from './components/work/Work'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import ScrollUp from './components/scrollup/ScrollUp'
import { MouseSmooth } from 'react-mouse-smooth'

const App = () => {
  MouseSmooth({
    time: 500,
  })

  return (
    <Fragment>
      <Header />
      <main className="main">
        <Home />
        <About />
        <Qualification />
        <Services />
        <Skills />
        <Work />
        <Contact />
      </main>
      <Footer />
      <ScrollUp />
    </Fragment>
  )
}

export default App
