import React, { useEffect } from "react";
import '../../utils/i18n'
import { useTranslation } from 'react-i18next'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Aplicativos = () => {
  const {t} = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    })
  }, [])

  return (
    <div data-aos="fade-left" className="skills__content">
    <h3 className="skills__title">Apps</h3>

    <div className="skills__box">
      <div className="skills__group">
        <div className="skills__data">
        <i className='bx bx-badge-check' ></i>

        <div>
          <h3 className="skills__name">VSCode</h3>
            <span className="skills__level">{t('')}</span>
        </div>
        </div>

        <div className="skills__data">
        <i className='bx bx-badge-check' ></i>

        <div>
          <h3 className="skills__name">Notion</h3>
            <span className="skills__level">{t('')}</span>
        </div>
        </div>

        <div className="skills__data">
        <i className='bx bx-badge-check' ></i>

        <div>
          <h3 className="skills__name">Chrome</h3>
            <span className="skills__level">{t('')}</span>
        </div>
        </div>

        <div className="skills__data">
        <i className='bx bx-badge-check' ></i>

        <div>
          <h3 className="skills__name">Discord</h3>
            <span className="skills__level">{t('')}</span>
        </div>
        </div>
      </div>

      <div className="skills__group">
      <div className="skills__data">
        <i className='bx bx-badge-check' ></i>

        <div>
          <h3 className="skills__name">Spotify</h3>
            <span className="skills__level">{t('')}</span>
        </div>
        </div>

        <div className="skills__data">
        <i className='bx bx-badge-check' ></i>

        <div>
          <h3 className="skills__name">Slack</h3>
            <span className="skills__level">{t('')}</span>
        </div>
        </div>
        
        <div className="skills__data">
        <i className='bx bx-badge-check' ></i>

        <div>
          <h3 className="skills__name">Safari</h3>
            <span className="skills__level">{t('')}</span>
        </div>
        </div>

        <div className="skills__data">
        <i className='bx bx-badge-check' ></i>

        <div>
          <h3 className="skills__name">Vercel</h3>
            <span className="skills__level">{t('')}</span>
        </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Aplicativos