import Work1 from "../../assets/work1.png"
import Work2 from "../../assets/work2.png"
import Work3 from "../../assets/work3.png"
import Work4 from "../../assets/work4.png"
import Work5 from "../../assets/work5.png"
import Work6 from "../../assets/work-preview.jpg"

export const projectsData = [
  {
    id: 1,
    image: Work1,
    title: 'ToDo App',
    tech: "Techs: #",
    category: 'Web',
    demo: "https://todo-app-mattchagaz.vercel.app/",
    repository: "https://github.com/mattchagaz/todo-app"
  },

  {
    id: 2,
    image: Work2,
    title: 'Tatuaria Mendes - Art Concept',
    tech: "Techs: #",
    category: 'Web',
    demo: "https://www.tatuariamendes.com",
    repository: "#"
  },

  {
    id: 3,
    image: Work3,
    title: 'Payment Card',
    tech: "Techs: #",
    category: 'Web',
    demo: "https://explorer-lab-mattchagaz.vercel.app/",
    repository: "https://github.com/mattchagaz/explorer-lab"
  },

  {
    id: 4,
    image: Work4,
    title: 'FlixNow',
    tech: "Techs: #",
    category: 'Web',
    demo: "https://flix-landing-page-three.vercel.app/",
    repository: "https://github.com/mattchagaz/flix-landing-page"
  },

  {
    id: 5,
    image: Work5,
    title: 'My personal landing page',
    tech: "Techs: #",
    category: 'Web',
    demo: "https://nlw-esports-explorer-lemon.vercel.app/",
    repository: "https://github.com/mattchagaz/nlw-esports-explorer"
  },

  {
    id: 6,
    image: Work6,
    title: 'Em Breve',
    tech: "Techs: #",
    category: 'App',
    demo: "",
    repository: ""
  },
]; 

export const projectsNav = [
  {
    name: 'All', 
  },

  {
    name: 'Web', 
  },

  {
    name: 'App', 
  },
] 