import React, { useEffect } from "react";
import "./skills.css"
import Frontend from "./Frontend";
import Backend from "./Backend";
import Design from "./Design"
import Aplicativos from "./Aplicativos"
import '../../utils/i18n'
import { useTranslation } from 'react-i18next'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Skills = () => {
  const {t} = useTranslation();
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    })
  }, [])


  return (
    <section className="skills section" id="skills">
      <h2 data-aos="fade-down" className="section__title">{t('Stack and uses')}</h2>
      <h2 data-aos="fade-down" className='section__subtitle'>{t('Explore as tecnologias e ferramentas que impulsionam a minha experiência no desenvolvimento')}</h2>

      <div className="skills__container container grid">
        <Frontend />
        <Backend />
        <Design />
        <Aplicativos />
      </div>
    </section>
  ) 
}

export default Skills