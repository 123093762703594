import React, { useState } from "react";
import "./qualification.css"
import '../../utils/i18n'
import { useTranslation } from 'react-i18next'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Qualification = () => {
  const {t} = useTranslation();
  const [toggleState, setToggleState] = useState(1)

  const toggleTab = (index) => {
    setToggleState(index);
  }

  useState(() => {
    AOS.init({
      duration: 800,
      once: true,
    })
  }, [])

    return (
        <section className="qualification section" id="qualification">
          <h2 data-aos="fade-right" className="section__title">{t('Qualification')}</h2>

          <div className="qualification__container container">
            <div className="qualification__tabs">
              <div className={toggleState === 1 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}
              onClick={() => toggleTab(1)}
            >
                <i className="uil uil-graduation-cap qualification__icon"></i> {t('Education')}
              </div>
          
              <div className={toggleState === 2 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}
              onClick={() => toggleTab(2)} >
                 <i className="uil uil-briefcase qualification__icon"></i> {t('Experiences')}
             </div> 
            </div>

            <div data-aos="flip-right" className="qualification__sections">
              <div className={toggleState === 1 ? "qualification__content qualification__content-active" : "qualification__content"}>
                <div className="qualification__data">
                  <div>
                    <h3 className="qualification__title">{t('App Developer')}</h3>
                    <span className="qualification__subtitle">Rocketseat - Ignite <br/> Startto.dev - Flutter </span>
                    <div className="qualification__calender">
                      <i className="uil uil-calendar-alt"></i> 2023 - 2024
                    </div>
                  </div>

                  <div>
                    <span className="qualification__rounder"></span>
                    <span className="qualification__line"></span>
                  </div>
                </div>

                <div className="qualification__data">
                  <div></div>

                  <div>
                    <span className="qualification__rounder"></span>
                    <span className="qualification__line"></span>
                  </div>

                  <div>
                    <h3 className="qualification__title">{t('Web Developer')}</h3>
                    <span className="qualification__subtitle">Rocketseat - Discover & Explorer</span>
                    <div className="qualification__calender">
                      <i className="uil uil-calendar-alt"></i> 2021 - 2022
                    </div>
                  </div>
                </div>

                <div className="qualification__data">
                  <div>
                    <h3 className="qualification__title">{t('Computer Technician')}</h3>
                    <span className="qualification__subtitle">QI {t('Escolas & Faculdades')} - {t('Instituto')}</span>
                    <div className="qualification__calender">
                      <i className="uil uil-calendar-alt"></i> 2020 - 2021
                    </div>
                  </div>

                  <div>
                    <span className="qualification__rounder"></span>
                    <span className="qualification__line"></span>
                  </div>
                </div>

                <div className="qualification__data">
                  <div></div>

                  <div>
                    <span className="qualification__rounder"></span>
                    <span className="qualification__line"></span>
                  </div>

                  <div>
                    <h3 className="qualification__title">{t('English Proficient')}</h3>
                    <span className="qualification__subtitle">QI {t('Escolas & Faculdades')} - {t('Instituto')}</span>
                    <div className="qualification__calender">
                      <i className="uil uil-calendar-alt"></i> 2017 - 2019
                    </div>
                  </div>
                </div>
              </div>

              <div className={toggleState === 2 ? "qualification__content qualification__content-active" : "qualification__content"}>
                <div className="qualification__data">
                  <div>
                    <h3 className="qualification__title">{t("Systems Development Analyst")}</h3>
                    <span className="qualification__subtitle">Digilivro | Livros Digitais </span>
                    <div className="qualification__calender">
                        <i className="uil uil-calendar-alt"></i> 2023 - {t("Present")}
                    </div>
                  </div>

                  <div>
                    <span className="qualification__rounder"></span>
                    <span className="qualification__line"></span>
                  </div>
                </div>

               {/* <div className="qualification__data">
                  <div></div>

                  <div>
                    <span className="qualification__rounder"></span>
                    <span className="qualification__line"></span>
                  </div>

                  <div>
                    <h3 className="qualification__title">Web Developer</h3>
                    <span className="qualification__subtitle">Rocketseat - Discover</span>
                    <div className="qualification__calender">
                      <i className="uil uil-calendar-alt"></i> 2021 - 2022
                    </div>
                  </div>
                </div>

                <div className="qualification__data">
                  <div>
                    <h3 className="qualification__title">Web Developer</h3>
                    <span className="qualification__subtitle">Rocketseat - Discover</span>
                    <div className="qualification__calender">
                      <i className="uil uil-calendar-alt"></i> 2021 - 2022
                    </div>
                  </div>

                  <div>
                    <span className="qualification__rounder"></span>
                    <span className="qualification__line"></span>
                  </div>
              </div> */}
              </div>
            </div>
          </div>
        </section>
    ) 
}

export default Qualification;   