import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Desc = () => {
  const {t} = useTranslation();
  useEffect(() => {
    AOS.init({duration: 800})
  }, [])


  return (
    <div data-aos="fade-left" className="about__info grid">
      <div className="about__box">
      <i className='bx bx-award about__icon' ></i>
      
        <h3 className="about__title">{t('Experiência')}</h3>
          <span className="about__subtitle">+3 {t('Anos')}</span>
      </div>

      <div className="about__box">
      <i className='bx bx-briefcase-alt about__icon' ></i>

        <h3 className="about__title">{t('Projetos')}</h3>
          <span className="about__subtitle">20+ {t('Projetos')}</span>
      </div>

      <div className="about__box">
      <i className='bx bx-support about__icon' ></i>

        <h3 className="about__title">{t('Suporte')}</h3>
          <span className="about__subtitle">Online 24/7</span>
      </div>
    </div>
  )
}

export default Desc