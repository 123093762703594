// Função principal que cria um hook personalizado para rolagem suave.
export default function useScrollTo() {
  let currentAnimation = null // Variável para controlar a animação de rolagem atual.

  // Função scrollTo que aceita as propriedades definidas na interface ScrollProps.
  const scrollTo = ({ to, parent, offset, duration = 1000 }) => {
    let targetElement

    // Determina o elemento de destino com base no tipo de 'to' (string ou RefObject).
    if (typeof to === 'string') {
      targetElement = document.querySelector(to)
    } else {
      targetElement = to.current
    }

    // Determina o elemento pai com base na propriedade 'parent'.
    const parentElement = parent ? document.querySelector(parent) : window

    // Verifica se os elementos de destino e pai são válidos.
    if (!targetElement || !parentElement) {
      console.error('Elemento de destino inválido ou elemento pai inválido')
      return
    }

    // Calcula a posição final de rolagem levando em consideração o deslocamento.
    const target =
      offset < 0
        ? targetElement.offsetTop - Math.abs(offset)
        : !offset
        ? targetElement.offsetTop
        : targetElement.offsetTop + offset

    // Função que realiza a animação de rolagem suave.
    const smoothScrollTo = (endX, endY, duration) => {
      const startX =
        parentElement instanceof Window
          ? parentElement.scrollX || parentElement.pageXOffset
          : parentElement.scrollLeft
      const startY =
        parentElement instanceof Window
          ? parentElement.scrollY || parentElement.pageYOffset
          : parentElement.scrollTop
      const distanceX = endX - startX
      const distanceY = endY - startY
      const startTime = new Date().getTime()

      duration = typeof duration !== 'undefined' ? duration : 1000

      // Função de interpolação para suavizar a animação de rolagem.
      const easeInOutQuart = (time, from, distance, duration) => {
        if ((time /= duration / 2) < 1)
          return (distance / 2) * time * time * time * time + from
        return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from
      }

      // Função que atualiza a posição de rolagem a cada frame.
      const animateScroll = () => {
        const time = new Date().getTime() - startTime
        let newX = easeInOutQuart(time, startX, distanceX, duration)
        let newY = easeInOutQuart(time, startY, distanceY, duration)

        if (time >= duration) {
          newX = endX
          newY = endY
          removeScrollLock()
        }

        // Atualiza a posição de rolagem com base no tipo de elemento pai.
        if (parentElement instanceof Window) {
          parentElement.scrollTo(newX, newY)
        } else {
          parentElement.scrollLeft = newX
          parentElement.scrollTop = newY
        }

        if (newX === endX && newY === endY) {
          removeScrollLock()
        } else {
          // Continua a animação se necessário.
          currentAnimation = setTimeout(animateScroll, 1000 / 60)
        }
      }

      // Função que impede a rolagem durante a animação.
      const scrollLock = event => {
        event.preventDefault()
      }

      // Adiciona o bloqueio de rolagem.
      const addScrollLock = () => {
        parentElement.addEventListener('wheel', scrollLock, { passive: false })
      }

      // Remove o bloqueio de rolagem.
      const removeScrollLock = () => {
        parentElement.removeEventListener('wheel', scrollLock)
      }

      addScrollLock() // Adiciona o bloqueio de rolagem.
      animateScroll() // Inicia a animação de rolagem.
    }

    // Cancela a animação de rolagem atual se estiver próxima do fim.
    if (currentAnimation) {
      clearTimeout(currentAnimation)
    }

    smoothScrollTo(0, target, duration)
  }

  return scrollTo
}
