import React, { useEffect } from "react";
import '../../utils/i18n'
import { useTranslation } from 'react-i18next'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Design = () => {
  const {t} = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    })
  }, [])

  return (
    <div data-aos="fade-right" className="skills__content">
    <h3 className="skills__title">Design</h3>

    <div className="skills__box">
      <div className="skills__group">
        <div className="skills__data">
        <i className='bx bx-badge-check' ></i>

        <div>
          <h3 className="skills__name">Adobe Photoshop</h3>
            <span className="skills__level">{t('Avançado')}</span>
        </div>
        </div>

        <div className="skills__data">
        <i className='bx bx-badge-check' ></i>

        <div>
          <h3 className="skills__name">Figma</h3>
            <span className="skills__level">{t('Intermediário')}</span>
        </div>
        </div>
      </div>

      <div className="skills__group">
      <div className="skills__data">
        <i className='bx bx-badge-check' ></i>

        <div>
          <h3 className="skills__name">Adobe Illustrator</h3>
            <span className="skills__level">{t('Básico')}</span>
        </div>
        </div>

        <div className="skills__data">
        <i className='bx bx-badge-check' ></i>

        <div>
          <h3 className="skills__name">Canva</h3>
            <span className="skills__level">{t('Intermediário')}</span>
        </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Design