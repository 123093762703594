import React, { createElement, useEffect } from "react";
import "./about.css";
import AboutImg from "../../assets/foto1.png";
import CV from "../../assets/CV-Matheus.pdf";
import Info from './Desc'
import '../../utils/i18n'
import { useTranslation } from 'react-i18next'
import Button from "../button/Button";
import AOS from 'aos';
import 'aos/dist/aos.css';


const About = () => {
const {t} = useTranslation();
const download = () => {
  const a = document.createElement('a');
  a.href = CV;
  a.download = 'CV-Matheus.pdf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
}, [])

  return (
    <section className="about section" id="about">
      <h2 className="section__title" data-aos="fade-right">{t('about')}</h2>


      <div className="about__container container grid">
        <img data-aos="fade-up" src={AboutImg} alt="" className="about__img" />

        <div className="about__data">
          <Info />

          <p data-aos="fade-right" className="about__description">👨‍💻 {t('Oi! Meu nome é Matheus Chagas. Eu tenho formação técnica em informática. Atualmente, estou me especializando no front-end com foco no Desenvolvimento de Aplicações Web utlizando o ecossistema JavaScript, e utilizando Flutter para Desenvolvimento Mobile.')}</p>

          <div data-aos="fade-up" className="about__span grid">
            <p>{t('Idade')}:...
              <span className="span__answer">20</span></p>
            <p>{t('Freelance')}:...
              <span className="span__answer">{t('Disponível')}</span></p>
            <p>{t('Tel')}:...
              <span className="span__answer">+55 51 99299-4382</span></p>
            <p>{t('Nacionalidade')}:...
              <span className="span__answer">{t('Brasileiro')}</span></p>
            <p>{t('Endereço')}:...
              <span className="span__answer">Porto Alegre/RS</span></p>
          </div>

          <Button onClick={() => download()} icon={<i className="uil uil-file-download"></i>} text={"Download CV"}/> 
        </div>
      </div>
    </section>
  )
}

export default About