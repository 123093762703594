import React, { useState } from 'react';
import "./footer.css"
import { useTranslation } from 'react-i18next'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Footer = () => {
  const {t} = useTranslation();

  useState(() => {
    AOS.init({
      duration: 800,
      once: true,
    })
  }, [])

  const year = new Date();


  return (
    <footer data-aos="fade-in" className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">Matheus Chagas</h1>

        <div className="footer__social">
        <a href="https://wa.me/5551992994382" className="footer__social-link" target="_blank" rel="noreferrer">
        <i className="uil uil-whatsapp"></i>
      </a>

      <a href="https://www.linkedin.com/in/matheusferrazchagas/" className="footer__social-link" target="_blank" rel="noreferrer">
        <i className="uil uil-linkedin-alt"></i>
      </a>

      <a href="https://github.com/mattchagaz" className="footer__social-link" target="_blank" rel="noreferrer">
       <i className="uil uil-github"></i>
      </a>
        </div>

        <span className='footer__copy'> Copyright
          &#169; {year.getFullYear()} <a href="https://github.com/mattchagaz">Mattchagaz.</a> {t("All rights reserved.")}
        </span>
      </div>
    </footer>
  );
};

export default Footer;